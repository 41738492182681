//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory }  from 'react-router';

import CalculationFields        from '@constants/CalculationFields';
import CalculationSteps         from '@constants/CalculationSteps';
import Routes                   from '@constants/Routes';
import useContextTranslator     from '@hooks/ContextTranslator';
import { useCalculation }       from '@slices/calculation';
import IconType                 from '@stateless/atomic/Icon/IconType';
import ConfirmModal             from '@stateless/composed/ConfirmModal';
import selectCurrentCalculation from '@store/selectors/calculation';

const propTypes = {};

const CalculationSaveConfirm = () => {
    const translator                      = useContextTranslator('screens.calculationScreen.saveConfirm');
    const history                         = useHistory();
    const currentRoute                    = history.location.pathname;
    const orderedCalculationRoutes        = [
        Routes.calculationCustomer,
        Routes.calculationObject,
        Routes.calculationFinance,
        Routes.calculationResult,
    ];
    const dispatch                        = useDispatch();
    const calculation                     = useSelector(selectCurrentCalculation);
    const calculationActions              = useCalculation(dispatch);
    const [showModal, setShowModal]       = useState(false);
    const [nextLocation, setNextLocation] = useState(null);

    useEffect(() => {
        const unblock = history.block((location) => {
            const currentStep = _.get(calculation, CalculationFields.step);

            if (
                !_.includes(orderedCalculationRoutes, location.pathname) &&
                _.includes(orderedCalculationRoutes, currentRoute) &&
                currentStep === CalculationSteps.result &&

                !nextLocation
            ) {
                setNextLocation(location);
                setShowModal(true);

                return false;
            }

            return true;
        });

        return () => unblock();
    }, [history, calculation, currentRoute, orderedCalculationRoutes]);

    function forwardToNextStep() {
        history.push(nextLocation.pathname);
        setNextLocation(null);
    }

    function onSaveConfirmed() {
        setShowModal(false);
        calculationActions.saveCalculation();
        forwardToNextStep();
    }

    function onClose(backgroundClick) {
        if (backgroundClick) {
            return;
        }

        setShowModal(false);
        forwardToNextStep();
    }

    return (
        <ConfirmModal
            show={showModal}
            fullHeight={false}
            onConfirm={onSaveConfirmed}
            confirmButtonIcon={IconType.save}
            confirmButtonText={'confirmCalculationSave'}
            cancelButtonText={'cancelCalculation'}
            title={translator.t('title')}
            text={translator.t('text')}
            onClose={onClose}
            disableAutoClose={true}
        />
    );
};

CalculationSaveConfirm.propTypes = propTypes;

export default CalculationSaveConfirm;
