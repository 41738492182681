//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _        from 'lodash';
import { put }  from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import * as Api              from '@api/index';
import Notification          from '@helper/Notification';
import SagaStateHelper       from '@helper/SagaStateHelper';
import { LoadingActions }    from '@slices/loading';
import { WidgetActions }     from '@slices/widget';
import { selectUserId }      from '@store/selectors/widget';
import { selectCalculation } from '@store/selectors/widget';

function* fetchUnits() {
    const userId = yield SagaStateHelper.selector(selectUserId);

    if (!userId) {
        yield put(LoadingActions.decreaseLevel({
            loadingAction: WidgetActions.fetchUnits(),
        }));

        return;
    }

    const response = yield call(
        Api.context.unit.fetchWidgetUnits,
        userId,
    );

    if (response.ok) {
        const units = _.get(response.data, 'member', []);

        yield put(WidgetActions.fetchUnitsSucceeded({
            units,
        }));
    } else {
        yield put(WidgetActions.fetchUnitsFailed());
    }
}

function* fetchCalculationResult() {
    const calculation = yield SagaStateHelper.selector(selectCalculation);
    const unit        = _.get(calculation, 'unit', null);

    if (!unit) {
        yield put(LoadingActions.decreaseLevel({
            loadingAction: WidgetActions.fetchCalculationResult(),
        }));

        return;
    }

    const response = yield call(
        Api.context.calculation.fetchCalculationResult,
        {
            ...calculation,
            unit: _.get(calculation, 'unit.iri', null),
        },
    );

    if (response.ok) {
        const data = response.data;

        yield put(WidgetActions.fetchCalculationResultSucceeded({
            data,
        }));
    } else {
        yield put(WidgetActions.fetchCalculationResultFailed());
    }
}

function* fetchColorSettings() {
    const userId = yield SagaStateHelper.selector(selectUserId);

    if (!userId) {
        yield put(LoadingActions.decreaseLevel({
            loadingAction: WidgetActions.fetchColorSettings(),
        }));

        return;
    }

    const response = yield call(
        Api.context.colorSettings.fetchWidgetColorSettings,
        userId,
    );

    if (response.ok) {
        const colorSettings = response.data;

        yield put(WidgetActions.fetchColorSettingsSucceeded({
            colorSettings,
        }));
    } else {
        yield put(WidgetActions.fetchColorSettingsFailed());
    }
}

function* triggerFetchCalculationResult() {
    yield put(WidgetActions.fetchCalculationResult());
}

function* setUserId() {
    yield put(WidgetActions.fetchUnits());
    yield put(WidgetActions.fetchColorSettings());
    yield triggerFetchCalculationResult();
}

function mapAddress(unit) {
    return {
        ..._.omit(unit, ['street', 'zipCode', 'city']),
        address: {
            street:      _.get(unit, 'street', ''),
            houseNumber: _.get(unit, 'houseNumber', ''),
            postalCode:  _.get(unit, 'postalCode', ''),
            city:        _.get(unit, 'city', ''),
        },
    };
}

function* sendContactForm(action) {
    const contactForm = _.get(action, 'payload.contactForm', {});
    const userId      = yield SagaStateHelper.selector(selectUserId);
    const calculation = yield SagaStateHelper.selector(selectCalculation);
    const unit        = _.get(calculation, 'unit.iri', null);
    const userString  = `/api/users/${userId}`;
    const response    = yield call(
        Api.context.contactForm.sendContactForm,
        {
            ...mapAddress(contactForm),
            unit,
            user:              userString,
            equity:            _.get(calculation, 'equity'),
            income:            _.get(calculation, 'income'),
            civilStatus:       _.get(calculation, 'civilStatus'),
            churchTax:         _.get(calculation, 'churchTax'),
            calculationFields: {
                ...calculation,
            },
        },
    );

    if (response.ok) {
        yield put(WidgetActions.sendContactFormSucceeded());
    } else {
        yield put(WidgetActions.sendContactFormFailed());
    }
}

function* sendContactFormFailed() {
    Notification.error('widgetContactForm.error');
}

function* sendContactFormSucceeded() {
    Notification.success('widgetContactForm.success');
}

export default {
    fetchUnits,
    fetchCalculationResult,
    triggerFetchCalculationResult,
    setUserId,
    fetchColorSettings,
    sendContactForm,
    sendContactFormFailed,
    sendContactFormSucceeded,
};
