//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { all }             from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';

import { AlertBoxActions }              from '@slices/alertBox';
import { BrowserActions }               from '@slices/browser';
import { ChangeTrackerActions }         from '@slices/changeTracker';
import { ConfigurationActions }         from '@slices/configuration';
import { ExampleActions }               from '@slices/example';
import { InventoryActions }             from '@slices/inventory';
import { LoadingActions }               from '@slices/loading'; // TODO-Skeleton
import { NavigationActions }            from '@slices/navigation';
import { PreloadActions }               from '@slices/preload';
import { SmartUpdaterActions }          from '@slices/smartUpdater'; // TODO-Skeleton
import { StagingAuthenticationActions } from '@slices/stagingAuthentication'; // TODO-Skeleton
import { TranslationsActions }          from '@slices/translations'; // TODO-Skeleton
import { WidgetActions }                from '@slices/widget';
import { WindowActions }                from '@slices/window'; // TODO-Skeleton

// Sagas
import AlertBoxSagas                    from './alertBox'; // TODO-Skeleton
import BrowserSagas                     from './browser'; // TODO-Skeleton
import CalculationSagas                 from './calculation';
import ChangeTrackerSagas               from './changeTracker'; // TODO-Skeleton
import ConfigurationSagas               from './configuration'; // TODO-Skeleton
import CustomerSagas                    from './customer';
import DashboardSagas                   from './dashboard';
import ExampleSagas                     from './example'; // TODO-Skeleton
import InventorySagas                   from './inventory'; // TODO-Skeleton
import LoadingSagas                     from './loading';
import NavigationSagas                  from './navigation';
import ObjectSagas                      from './object';
import PreLoadSagas                     from './preload';
import SmartUpdaterSagas                from './smartUpdater';
import StagingAuthenticationSagas       from './stagingAuthentication'; // TODO-Skeleton
import SubscriptionOptionSagas          from './subscriptionOption';
import TranslationsSagas                from './translations'; // TODO-Skeleton
import UnitSagas                        from './unit';
import UserSagas                        from './user';
import WidgetSagas                      from './widget';
import WindowSagas                      from './window';

const callAlertBoxSagas = () => {
    return [
        // @formatter:off
        takeLatest([AlertBoxActions.showErrorAlertTranslated().type],   AlertBoxSagas.showErrorAlertTranslated),
        takeLatest([AlertBoxActions.showSuccessAlertTranslated().type], AlertBoxSagas.showSuccessAlertTranslated),
        // @formatter:on
    ];
};

const callBrowserSagas = () => {
    return [
        // @formatter:off
        takeLatest([BrowserActions.scrollToTop().type],              BrowserSagas.scrollToTop),
        takeLatest([BrowserActions.scrollToTopReactDataGrid().type], BrowserSagas.scrollToTopReactDataGrid),
        // @formatter:on
    ];
};

const callChangeTrackerSagas = () => {
    return [
        // @formatter:off
        takeLatest([ChangeTrackerActions.checkChanges().type],        ChangeTrackerSagas.checkChanges),
        takeLatest([ChangeTrackerActions.saveReferenceObject().type], ChangeTrackerSagas.saveReferenceObject),
        // @formatter:on
    ];
};

const callConfigurationSagas = () => {
    return [
        // @formatter:off
        takeLatest([ConfigurationActions.fetchConfiguration().type], ConfigurationSagas.fetchConfiguration),
        // @formatter:on
    ];
};

const callExampleSagas = () => {
    return [
        // @formatter:off
        takeLatest([ExampleActions.createExample().type], ExampleSagas.createExample),
        // @formatter:on
    ];
};

const callInventorySagas = () => {
    return [
        // @formatter:off
        takeLatest([InventoryActions.fetchItems().type], InventorySagas.fetchItems),
        // @formatter:on
    ];
};

const callSmartUpdaterSagas = () => {
    return [
        // @formatter:off
        takeLatest([SmartUpdaterActions.reloadData().type],    SmartUpdaterSagas.reloadData),
        takeLatest([SmartUpdaterActions.reloadAllData().type], SmartUpdaterSagas.reloadAllData),
        // @formatter:on
    ];
};

const callStagingAuthenticationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.resetState().type],              StagingAuthenticationSagas.reset),
        takeLatest([StagingAuthenticationActions.authenticate().type], StagingAuthenticationSagas.authenticate),
        // @formatter:on
    ];
};

const translationsSagas = () => {
    return [
        // @formatter:off
        takeLatest([TranslationsActions.fetchTranslations().type], TranslationsSagas.fetchTranslations),
        // @formatter:on
    ];
};

const callLoadingSagas = () => {
    return [
        // @formatter:off
        takeLatest([LoadingActions.overlayClicked().type], LoadingSagas.overlayClicked),
        // @formatter:on
    ];
};

const callNavigationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.redirect().type], NavigationSagas.redirect),
        // @formatter:on
    ];
};

const callLocationChangeSagas = () => {
    return [
        // @formatter:off
        takeLatest([LOCATION_CHANGE], NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE], LoadingSagas.resetClickCount),
        // @formatter:on
    ];
};

const callWindowSagas = () => {
    return [
        // @formatter:off
        takeLatest([WindowActions.windowBecameVisible().type], WindowSagas.windowBecameVisible),
        takeLatest([WindowActions.windowGotFocus().type],      WindowSagas.windowGotFocus),
        // @formatter:on
    ];
};

const callWidgetSagas = () => {
    return [
        // @formatter:off
        takeLatest([WidgetActions.fetchUnits().type],               WidgetSagas.fetchUnits),
        takeLatest([WidgetActions.fetchCalculationResult().type],   WidgetSagas.fetchCalculationResult),
        takeLatest([WidgetActions.setUserId().type],                WidgetSagas.setUserId),
        takeLatest([WidgetActions.setCalculationFields().type],     WidgetSagas.triggerFetchCalculationResult),
        takeLatest([WidgetActions.fetchColorSettings().type],       WidgetSagas.fetchColorSettings),
        takeLatest([WidgetActions.sendContactForm().type],          WidgetSagas.sendContactForm),
        takeLatest([WidgetActions.sendContactFormFailed().type],    WidgetSagas.sendContactFormFailed),
        takeLatest([WidgetActions.sendContactFormSucceeded().type], WidgetSagas.sendContactFormSucceeded),
        // @formatter:on
    ];
};

const callRehydrateSagas = () => {
    return [
        // @formatter:off
        takeLatest([REHYDRATE],                         LoadingSagas.rehydrate),
        takeLatest([REHYDRATE],                         PreLoadSagas.rehydrate),
        takeLatest([PreloadActions.preLoadData().type], PreLoadSagas.preLoadData),
        // @formatter:on
    ];
};

function* root() {
    yield all([
        // TODO-Skeleton start
        ...callAlertBoxSagas(),
        ...callBrowserSagas(),
        ...callChangeTrackerSagas(),
        ...callConfigurationSagas(),
        ...callExampleSagas(),
        ...callInventorySagas(),
        ...callSmartUpdaterSagas(),
        ...callStagingAuthenticationSagas(),
        ...callLoadingSagas(),
        ...callNavigationSagas(),
        ...callLocationChangeSagas(),
        ...callWindowSagas(),
        ...callRehydrateSagas(),
        ...translationsSagas(),
        // TODO-Skeleton end
        ...callWidgetSagas(),
        ...UserSagas.callSagas(),
        ...ObjectSagas.callSagas(),
        ...UnitSagas.callSagas(),
        ...CustomerSagas.callSagas(),
        ...CalculationSagas.callSagas(),
        ...DashboardSagas.callSagas(),
        ...SubscriptionOptionSagas.callSagas(),
    ]);
}

export default {
    root,
};
